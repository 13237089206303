<template>
  <div class="result container">
    <a-result :title="title" :sub-title="subTitle">
      <template #icon>
        <img width="150" :src="image" alt="" srcset="">
      </template>
      <template #extra>
        <van-button v-if="status === 'success'" @click="onSuccessHandler" class="result-btn" plain round color="#5D5D5D">{{$t('common.check_order')}}</van-button>
        <van-button v-if="status === 'fail'&&userAgent!=='other'" @click="onFailHandler" class="result-btn" round color="#B50000">{{$t('common.pay_again')}}</van-button>
        <van-button v-if="userAgent==='other'" @click="$router.push({ name: 'Home' })" class="result-btn" round color="#2564f6">{{$t('common.back_home')}}</van-button>
      </template>
    </a-result>
  </div>
</template>

<script>
  import { Result } from 'ant-design-vue'
  export default {
    name: 'ResultView',
    components: { 
      'a-result':Result
    },
    data() {
      return {
        title: 'Result',
        subTitle:'',
        status:'',
        userAgent:'',
        image:'',
      }
    },
    mounted() {
      const system = navigator.userAgent.toLowerCase()
			if(system.includes('triproaming')&&system.includes('ios')){
        this.userAgent = "ios"
      } else if(system.includes('triproaming')&&system.includes('android')){
        this.userAgent = "android"
      }else{
        this.userAgent = "other"
      }
      const { order_no,r } = this.$route.query;
      this.status = r === 'success' ? 'success' : 'fail'
      this.image = r === 'success' ? 'https://img.triproaming.cn/weapp/result_success.png' : 'https://img.triproaming.cn/weapp/result_fail.png'
      this.title = r === 'success' ? this.$t('common.result_success') : this.$t('common.result_fail')
      document.title = this.title
      if(this.userAgent === 'other' && r === 'success') this.subTitle = this.$t('common.result_tips',{text:order_no})
    },
    methods: {
      onSuccessHandler(){
        const { order_no,order_type } = this.$route.query;
        if(this.userAgent === "ios"){
          window.webkit.messageHandlers.goOrderDetailPage.postMessage({type:order_type,orderId:order_no});
        }else if(this.userAgent === "android"){
          window.hotelapp.backToHomePage()
        }else{
          const name = order_type == '1' ? 'MyCards' : 'SimOrder';
          this.$router.push({ name });
          
        }
      },
      onFailHandler(){
        const { order_no,order_type } = this.$route.query;
        if(this.userAgent === "ios"){
          window.webkit.messageHandlers.goOrderDetailPage.postMessage({type:order_type,orderId:order_no});
        }else if(this.userAgent === "android"){
          window.hotelapp.closeWebView()
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.result{
  padding: 100px 0;
  /deep/ .ant-result{
    .ant-result-icon{
      margin-bottom: 0;
    }
    .ant-result-title{
      color: #5D5D5D;
      font-size: 20px;
      font-weight: bold;
    }
    .ant-result-extra{
      margin-top: 20px;
    }
  }
  .result-btn{
    min-width: 133px;
  }
}
</style>